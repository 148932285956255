import request from '@/utils/request'

export function get(id) {
  return request({
    url: "api/orderForm/" + id,
    method: "get"
  });
}

export function add(data) {
  return request({
    url: 'api/orderForm',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: 'api/orderForm',
    method: 'put',
    data
  })
}

export function audit(data) {
  return request({
    url: "api/orderForm/check",
    method: "put",
    data
  })
}

export function report(data) {
  return request({
    url: "api/orderForm/toErp",
    method: "put",
    data
  })
}

export function close(id) {
  return request({
    url: "api/orderForm/close",
    method: "post",
    data: {
      id: id
    }
  })
}

export function closeItem(id) {
  return request({
    url: "api/orderForm/closeRows",
    method: "post",
    data: {
      id: id
    }
  })
}

export function getDistributorGoodsPrice(params) {
  return request({
    url: 'api/purchaseGoods/price',
    method: 'get',
    params
  })
}

export function sumDetails(params) {
  return request({
    url: "api/orderFormFull/sum",
    method: "get",
    params
  })
}
export function sumDetailsBuyer(params) {
  return request({
    url: "api/orderFormFull/sum/buyer",
    method: "get",
    params
  })
}