<template>
  <el-dialog :visible.sync="dialog" title="付款情况" append-to-body :close-on-click-modal="false" width="1180px" @closed="handleClosed">
    <pays-info :order="order" :receipt="receipt" @paied="handlePaied" v-if="order" />
  </el-dialog>
</template>

<script>
import paysInfo from "./pays-info";

export default {
  components: { paysInfo },
  props: {
    receipt: Boolean //是否为收款信息, 否为付款信息
  },
  data() {
    return {
      order: null,
      dialog: false
    };
  },
  methods: {
    handleClosed() {
      this.order = null;
    },
    handlePaied() {
      this.$emit("paied");
    },
    show(data) {
      if (data && data.id) {
        this.order = data;
        this.dialog = true;
      }
    }
  }
};
</script>