<template>
  <div>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" custom-class="custom-dialog-max-width" @closed="form = null">
      <template v-if="form">
        <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right" v-loading="loading">
          <div class="h sb">
            <div>
              <form-info-item label="销售单号">{{form.formCode}}</form-info-item>
              <form-info-item label="ERP单号" v-if="form.erpFormCode && form.erpFormCode !== form.formCode">{{ form.erpFormCode }}</form-info-item>
              <form-info-item label="下单时间">{{new Date(form.purchaseTime).format()}}</form-info-item>
            </div>
            <div>
              <form-info-item label="经销商">
                {{ form.buyer && form.buyer.name }}&nbsp;
                <span v-if="form.buyerErpId">({{ form.buyerErpId }})</span>
              </form-info-item>
              <form-info-item label="收货人">{{ form.receiverName }}&nbsp;({{form.receiverPhone}})</form-info-item>
              <form-info-item label="收货地址">{{ form.recProvince }}{{ form.recCity }}{{ form.recDistrict}}{{ form.recAddress }}</form-info-item>
            </div>
          </div>
          <div class="padding-10-0 h c" v-if="editable">
            <sku-selector simple-query request-url="api/distributorGoods" :request-params="{ distributorId: form.buyerId }" button-text="添加商品" @submit="handleGoodsAdd" />
            <div class="padding-10 fc-e">注：已经参加促销活动（包含组合销售）的商品不可调整金额与数量。</div>
          </div>
          <el-table border :data="form.items" :highlight-current-row="!editable" empty-text="当前销售单没有选择商品" style="margin: 8px 0">
            <!-- <el-table-column prop="code" label="商品编码" min-width="130" fixed /> -->
            <el-table-column prop="erpCode" label="ERP编码" width="130" sortable fixed />
            <el-table-column label="商品" prop="goodsName" min-width="240" sortable fixed>
              <div class="h" style="line-height: 1.5" slot-scope="scope">
                <template v-if="scope.row.isGift">
                  <el-tag size="mini" type="danger">赠</el-tag>&emsp;
                </template>
                <div>
                  <div>{{ scope.row.goodsName }}</div>
                  <div class="fc-g">{{ $goodsSpecConvert(scope.row.goodsSpec) }}</div>
                  <template v-if="scope.row.promotionId">
                    <div class="fc-e" v-if="scope.row.promotionJoinType === 'groupSale'">组合销售：{{ scope.row.groupSaleName }}</div>
                    <template v-else>
                      <div class="fc-e" v-if="scope.row.promotionId">商品促销：{{ scope.row.promotionName }}</div>
                      <div class="fc-e" v-if="scope.row.wholePromotionId">整单优惠：{{ scope.row.wholePromotionName }}</div>
                    </template>
                  </template>
                </div>
              </div>
            </el-table-column>
            <el-table-column prop="goodsBrand" label="品牌" width="80" sortable v-if="!editable" />
            <el-table-column label="数量" prop="count" align="center" width="110" sortable>
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.count" :min="scope.row.minSalesQuantity || 0.01" :max="9999" :step="1" :precision="2" size="mini" controls-position="right" @change="$checkNaN(scope.row,'count',scope.row.minSalesQuantity || 0.01,loadGoodsPrice)" style="width: 100%" v-if="editable && !scope.row.promotionId" />
                <span v-else>{{ scope.row.count }}</span>
              </template>
            </el-table-column>
            <el-table-column label="单价" prop="realPrice" width="240" align="right" sortable>
              <template slot-scope="scope">
                <div class="v e" v-if="editable && !scope.row.promotionId">
                  <price-input size="mini" v-model="scope.row.realPrice" style="width: 100%" />
                  <div class="fc-g lh-100 ta-c" style="margin-top: 5px">
                    原价
                    <br />
                    {{ $price(scope.row.price) }}
                  </div>
                </div>
                <div v-else>
                  <b class="fc-e">{{ $price(scope.row._finalPrice) }}</b>
                  <div class="fc-g h e r lh-100 ta-c" v-if="scope.row.price > scope.row._finalPrice" v-html="scope.row._finalPrices"></div>
                </div>
                <!-- <div v-else>
                  <b class="fc-e">{{$price(scope.row.realPrice)}}</b>
                  <div class="fc-g lh-100 ta-c" style="margin-top: 5px;">
                    原价
                    <br />
                    {{$price(scope.row.price)}}
                  </div>
                </div>-->
              </template>
            </el-table-column>
            <template v-if="editable">
              <el-table-column key="eShipDate" label="交货日期" prop="shipDate" width="155" sortable>
                <template slot-scope="scope">
                  <el-date-picker v-model="scope.row.shipDate" :clearable="false" size="mini" value-format="timestamp" style="width: 100%" v-if="reportable || editable" />
                  <span v-else>{{new Date(scope.row.shipDate).format("yyyy/MM/dd")}}</span>
                </template>
              </el-table-column>
              <el-table-column key="eInfo" label="备注" width="320">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                </template>
              </el-table-column>
              <el-table-column key="eCmd" width="40">
                <div class="row-commands" slot-scope="scope">
                  <el-button type="text" size="mini" icon="el-icon-delete" class="danger" @click="handleGoodsRemove(scope.row)" v-if="!scope.row.promotionId" />
                </div>
              </el-table-column>
            </template>
            <template v-else>
              <el-table-column key="eInfo2" label="备注" width="320" v-if="reportable">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                </template>
              </el-table-column>
              <el-table-column key="eShipDate1" label="交货日期" prop="shipDate" width="155" sortable>
                <template slot-scope="scope">
                  <el-date-picker v-model="scope.row.shipDate" :clearable="false" size="mini" value-format="timestamp" style="width: 100%" v-if="reportable || editable" />
                  <span v-else>{{new Date(scope.row.shipDate).format("yyyy/MM/dd")}}</span>
                </template>
              </el-table-column>
              <el-table-column key="rebate" prop="_realRebateAmount" label="返利抵扣" align="right" width="100" :formatter="$price" v-if="form.status !== 0 && form.status !== 1 && form.status !== 3" />
              <el-table-column key="sendCount" prop="sendCount" label="已发货" align="center" width="70" />
              <el-table-column key="receivedCount" prop="receivedCount" label="已收货" align="center" width="70" />
              <el-table-column key="cancelCount" prop="cancelCount" label="已取消" align="center" width="70" />
              <el-table-column prop="retailFormCode" label="对应订单" min-width="120" align="center" />
              <el-table-column key="info" prop="info" label="备注" min-width="120" show-overflow-tooltip v-if="!reportable" />
              <el-table-column key="sum" label="小计" width="100" align="right" fixed="right">
                <template slot-scope="scope">
                  <b class="fc-e">{{ $price(scope.row._finalAmount) }}</b>
                </template>
              </el-table-column>
              <el-table-column key="cmd" width="60" fixed="right" v-if="closable">
                <div class="row-commands" slot-scope="scope">
                  <el-button type="text" v-permission="['WHOLESALE_ALL', 'WHOLESALE_CLOSE']" size="mini" class="danger" @click="doClose(scope.row)" v-if="scope.row.count - scope.row.sendCount - scope.row.cancelCount > 0">关闭</el-button>
                </div>
              </el-table-column>
            </template>
          </el-table>
          <div class="h s">
            <form-info-item label="支付方式" style="margin-right: 30px">{{payTypes[form.payType]}}</form-info-item>
            <div class="flex"></div>
            <form-info-item label="商品总数" label-width="auto" style="margin-right: 30px">
              <b>{{ totalCount }}</b>
            </form-info-item>
            <template v-if="editable">
              <div class="h c padding-10-5" style="margin-right: 20px">
                <div>统一设置交货日期：</div>
                <div class="c h">
                  <el-date-picker type="date" size="mini" v-model="deliveryDate" value-format="timestamp" placeholder="交货日期" style="width: 125px" />&nbsp;
                </div>
                <el-button type="info" size="mini" @click="applyDeliveryDate">应用</el-button>&nbsp;
              </div>
              <form-info-item label="商品金额" label-width="auto" style="margin-right: 30px">
                <b>{{ $price(totalMoney) }}</b>
              </form-info-item>
              <form-info-item label="优惠金额" label-width="auto">
                <b class="fc-e">{{ $price(discountAmount) }}</b>
              </form-info-item>
            </template>
            <template v-else>
              <template v-if="form.discountAmount > 0">
                <form-info-item label="商品金额" label-width="auto" style="margin-right: 30px">
                  <b>{{ $price(form.totalMoney) }}</b>
                </form-info-item>
                <form-info-item label="优惠金额" label-width="auto" style="margin-right: 30px">
                  <b class="fc-e">{{ $price(form.discountAmount) }}</b>
                </form-info-item>
              </template>
              <form-info-item label="订单金额" label-width="auto" style="padding-right: 30px">
                <b>{{$price((form.totalMoney || 0) - (form.discountAmount || 0))}}(实际成交：{{ $price(form.realTotalMoney || 0) }})</b>
              </form-info-item>
              <!-- <form-info-item label="实际成交金额" label-width="auto" content-style="padding-right: 30px;">
                <b>{{$price(form.realTotalMoney || 0)}}</b>
              </form-info-item>-->
              <form-info-item label="返利抵扣" label-width="auto" style="padding-right: 30px" v-if="form.useRebate && form.rebateAmount > 0">
                <b class="fc-e">{{ $price(form.rebateAmount || 0) }}(已确认：{{$price(form.realRebateAmount || 0)}})</b>
              </form-info-item>
              <form-info-item label="应付金额" label-width="auto">
                <b v-if="form.status===1">{{ $price( (form.realTotalMoney || 0) - (form.rebateAmount || 0) ) }}</b>
                <b v-else>{{ $price( (form.realTotalMoney || 0) - (form.realRebateAmount || 0) ) }}</b>
              </form-info-item>
            </template>
          </div>
          <div class="h s">
            <form-info-item class="flex" label="摘要" v-if="editable">
              <el-input type="textarea" v-model="form.info" resize="none" :rows="3" :maxlength="200" placeholder="有其他要求或需要说明的情况，请在备注中说明" />
            </form-info-item>
            <form-info-item class="flex" label="摘要" v-else>{{ form.info }}</form-info-item>
            <el-button type="info" size="mini" v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_PAY']) && !editable" @click="pay">付款情况</el-button>
          </div>
          <el-divider v-if="!auditable" />
          <div class="h c" v-if="!auditable">
            <template v-if="!editable">
              <form-info-item label="当前状态" style="margin-right: 50px">
                <dot same :type="status[form.status].type">{{ status[form.status].label }}</dot>
              </form-info-item>

              <form-info-item label="关闭人" style="margin-right: 50px" v-if="form.closeUser">{{form.closeUser}}</form-info-item>
              <form-info-item label="关闭时间" style="margin-right: 50px" v-if="form.closeTime">{{new Date(form.closeTime).format()}}</form-info-item>

              <form-info-item label="付款情况" v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_PAY'])">
                <dot same :type="payStatus[form.payType][form.payStatus].type">{{ payStatus[form.payType][form.payStatus].label }}</dot>
              </form-info-item>

              <template v-if="form.status > 1 && form.status !== 3 && $erp.enable()">
                <form-info-item label="供应来源" style="margin-left: 50px;" v-if="form.supplySource">{{form.supplySource === 1 ? '内部组织' : form.supplySource === 4 ? '当前组织' : ''}}</form-info-item>
                <form-info-item label="自动审核" style="margin-left: 50px;">{{form.needApprove ? "是" : "否"}}</form-info-item>
              </template>
            </template>
            <div class="flex"></div>
            <template>
              <el-button type="text" @click="dialog = false">取消</el-button>
              <el-button size="mini" type="primary" :loading="saving" @click="doSave" v-if="editable">保存</el-button>
              <el-button size="mini" :disabled="saving" v-permission="['WHOLESALE_ALL', 'WHOLESALE_AUDIT']" type="primary" @click="switchAudit" v-if="form.status === 1">审批</el-button>
              <el-button type="primary" @click="doPrint">打印销售单</el-button>
            </template>
          </div>
        </el-form>

        <template v-if="auditable && audit.form">
          <el-divider content-position="left">{{reportable ? "上报" : "审批"}}</el-divider>
          <el-form :model="audit.form" :rules="audit.rules" label-position="right" label-width="88px" label-suffix=":">
            <div class="h e" v-if="$erp.enable()">
              <el-form-item prop="supplySource" label="供应来源">
                <quick-select v-model="audit.form.supplySource" url="api/sale/supplySource" auto-select-first-option clearable style="width: 240px; margin-right: 20px;" />
              </el-form-item>
              <el-form-item prop="needApprove" label="自动审核">
                <el-switch v-model="audit.form.needApprove" />
              </el-form-item>
            </div>
            <el-form-item>
              <el-button :loading="auditing" :disabled="saving" type="primary" @click="doAudit(true)">{{reportable ? "开始上报" : "通过"}}</el-button>
              <el-button :loading="auditing" :disabled="saving" type="danger" @click="doAudit(false)">{{reportable ? "取消订单" : "不通过"}}</el-button>
            </el-form-item>
          </el-form>
        </template>
      </template>
      <template v-else-if="loading">
        <div v-loading="loading" style="height: 100px"></div>
      </template>
    </el-dialog>
    <pays receipt ref="pays" />
  </div>
</template>

<script>
import {
  get,
  edit,
  audit,
  report,
  closeItem,
  close as closeOrder,
  getDistributorGoodsPrice,
} from "@/api/distributorOrder";
import { getDefaultAddress, getDefaultContact } from "@/api/receiving";
import skuSelector from "@/views/assembly/skuSelect";
import checkPermission from "@/utils/permission";
import pays from "../../purchase/order/pays";

export default {
  components: { skuSelector, pays },
  data() {
    return {
      deliveryDate: null,
      loading: false,
      saving: false,
      auditing: false,
      auditable: false,
      reportable: false,
      dialog: false,
      form: null,
      defaultShipDate: null,
      payTypes: ["线上支付", "线下支付"],
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "warning",
          label: "待审批",
        },
        {
          type: "success",
          label: "已确认",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "warning",
          label: "待发货",
        },
        {
          type: "success",
          label: "部分已发货",
        },
        {
          type: "success",
          label: "已发货",
        },
        {
          type: "success",
          label: "部分已收货",
        },
        {
          type: "success",
          label: "已完成",
        },
        {
          type: "info",
          label: "已关闭",
        },
        {
          type: "info",
          label: "已取消",
        },
        {
          type: "info",
          label: "部分已关闭",
        },
      ],
      goodsQueryDefine: {
        name: "",
      },
      rules: {
        buyerId: [{ required: true, message: "请选择供应商" }],
        items: [
          { required: true, message: "请选择要采购的商品" },
          { type: "array", min: 1, message: "请选择要采购的商品" },
        ],
        payType: [{ required: true, message: "请选择支付方式" }],
        receiverName: [{ required: true, message: "请填写收货人" }],
        receiverPhone: [{ required: true, message: "请填写收货人联系电话" }],
        recProvinceCode: [{ required: true, message: "请选择省份" }],
        recCityCode: [{ required: true, message: "请选择城市" }],
        recDistrictCode: [{ required: true, message: "请选择地区" }],
        recAddress: [{ required: true, message: "请填写详细地址" }],
      },
      audit: {
        form: null,
        rules: {},
      },
    };
  },
  computed: {
    title() {
      let str = "加载中…";
      if (this.form) {
        if (this.auditable && this.reportable) {
          str = "上报销售单";
        } else if (this.auditable && this.form.status === 1) {
          str = "审批销售单";
        } else if (this.editable) {
          str = "编辑销售单";
        } else {
          str = "查看销售单";
        }
      }
      return str;
    },
    totalCount() {
      let c = 0;
      for (let i = 0, l = this.form.items.length; i < l; i++) {
        let o = this.form.items[i];
        c += (o.count || 0) * 10000;
      }
      return Math.round(c) / 10000;
    },
    totalMoney() {
      let c = 0;
      for (let i = 0, l = this.form.items.length; i < l; i++) {
        let o = this.form.items[i];
        //if (typeof o.count !== "number" || o.count < 1) o.count = 1;
        c += Math.round((o.realPrice || 0) * o.count);
      }
      return c;
    },
    discountAmount() {
      let c = 0;
      for (let i = 0, l = this.form.items.length; i < l; i++) {
        let o = this.form.items[i];
        if (o.promotionId) c += o.discountAmount;
        else c += Math.round(((o.price || 0) - (o.realPrice || 0)) * o.count);
      }
      return c;
    },
    closable() {
      return (
        this.form &&
        !this.auditable &&
        [2, 4, 5, 7, 11].indexOf(this.form.status) >= 0
      );
    },
    editable() {
      return (
        this.form &&
        !this.auditable &&
        this.checkPermission(["WHOLESALE_ALL", "WHOLESALE_EDIT"]) &&
        this.form.status === 1
      );
    },
  },

  methods: {
    checkPermission,
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          edit(this.form)
            .then((res) => {
              this.$parent.init();
              if (typeof callback === "function") callback();
              else
                this.$notify({
                  title: `保存销售单成功`,
                  type: "success",
                  duration: 2500,
                });
            })
            .finally((_) => {
              this.saving = false;
            });
        });
    },
    switchAudit() {
      if (this.form && this.form.id) {
        this.doSave(() => {
          this.resetForm(this.form.id, true);
        });
      }
    },
    doAudit(pass) {
      if (this.form && this.form.id && this.auditable) {
        if (this.reportable) {
          if (pass) {
            this.$confirm("确定要上报该销售单吗？", "操作确认", {
              type: "warning",
              dangerouslyUseHTMLString: true,
            }).then((res) => {
              this.auditing = true;
              report(
                Object.assign({}, this.audit.form, {
                  id: this.form.id,
                  orderItems: this.form.items,
                })
              )
                .then((res) => {
                  this.$notify({
                    title: `上报销售单成功`,
                    type: "success",
                    duration: 2500,
                  });
                  this.dialog = false;
                  this.$parent.init();
                })
                .finally((_) => {
                  this.auditing = false;
                });
            });
          } else {
            this.$confirm("确定要关闭该销售单吗？", "操作确认", {
              type: "warning",
              dangerouslyUseHTMLString: true,
            }).then((res) => {
              this.auditing = true;
              closeOrder(this.form.id)
                .then((res) => {
                  this.$notify({
                    title: `关闭销售单成功`,
                    type: "success",
                    duration: 2500,
                  });
                  this.dialog = false;
                  this.$parent.init();
                })
                .finally((_) => {
                  this.auditing = false;
                });
            });
          }
        } else {
          this.$confirm(
            "审批销售单后将不能再进行修改，确定要" +
              (pass ? "通过" : "不通过") +
              "吗？",
            "审批确认",
            {
              type: "warning",
              dangerouslyUseHTMLString: true,
            }
          ).then((res) => {
            this.auditing = true;
            audit(
              Object.assign({}, this.audit.form, {
                id: this.form.id,
                result: pass,
              })
            )
              .then((res) => {
                this.$notify({
                  title: `审批销售单成功`,
                  type: "success",
                  duration: 2500,
                });
                this.dialog = false;
                this.$parent.init();
              })
              .finally((_) => {
                this.auditing = false;
              });
          });
        }
      }
    },
    doClose(row) {
      this.$confirm("您确定要取消当前行吗？", "操作确认", {
        type: "warning",
      }).then((_) => {
        closeItem(row.id).then((res) => {
          this.$notify({
            title: "取消行成功",
            type: "success",
            duration: 2500,
          });
          this.resetForm(this.form.id);
          this.$parent.init();
        });
      });
    },
    doPrint() {
      if (this.form && this.form.id) this.$print("wholesale", this.form.id);
    },
    loadGoodsPrice(g) {
      if (g && g.goodsId) {
        getDistributorGoodsPrice({
          goodsId: g.goodsId,
          quantity: g.count,
          buyerId: this.form.buyerId,
        }).then((res) => {
          g.price = res.goodsPrice;
          g.realPrice = res.goodsPrice;
        });
      }
    },
    resetForm(id = null, auditable = false, reportable = false) {
      this.auditable = auditable;
      this.reportable = reportable;
      if (this.auditable) {
        this.audit.form = {
          supplySource: null,
          needApprove: true,
        };
      }
      if (id) {
        this.loading = true;
        get(id)
          .then((res) => {
            (res.items || []).forEach((o) => {
              let _ps = [`<div>原价<br />${this.$price(o.price)}</div>`];
              o._finalPrice = o.price;
              if (o.discountAmount) {
                let _dp = Math.round(o.discountAmount / o.count);
                o._finalPrice -= _dp;
                _ps.push(`<div>促销优惠<br />${this.$price(_dp)}</div>`);
              }
              o._realRebateAmount = 0;
              if (o.rebateAmount) {
                let _rp = Math.round(o.rebateAmount / o.count);
                o._finalPrice -= _rp;
                o._realRebateAmount = _rp * (o.count - o.cancelCount);
                _ps.push(`<div>返利抵扣<br />${this.$price(_rp)}</div>`);
              }
              o._finalAmount = o._finalPrice * (o.count - o.cancelCount);
              o._finalPrices = _ps.join("<div>&nbsp;-&nbsp;</div>");
            });
            this.form = res;
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = {
          buyerId: null,
          info: "",
          recProvince: null,
          recProvinceCode: null,
          recCity: null,
          recCityCode: null,
          recDistrict: null,
          recDistrictCode: null,
          recAddress: null,
          receiverName: "",
          receiverPhone: "",
          items: [],
          payType: 0,
          status: 0,
          payStatus: 0,
        };
      }
      this.dialog = true;
    },
    handleDistributorChange() {
      Object.assign(this.form, {
        recProvince: null,
        recProvinceCode: null,
        recCity: null,
        recCityCode: null,
        recDistrict: null,
        recDistrictCode: null,
        recAddress: null,
        receiverName: "",
        receiverPhone: "",
        items: [],
      });
      getDefaultContact(this.form.buyerId).then((res) => {
        if (res) this.handleContactChange(null, null, res);
      });
      getDefaultAddress(this.form.buyerId).then((res) => {
        if (res) this.handleAddressChange(null, null, res);
      });
    },
    handleContactChange(ov, nv, row) {
      if (row) {
        let phones = [];
        if (row.mobilePhone) phones.push(row.mobilePhone);
        if (row.officePhone) phones.push(row.officePhone);

        this.form.receiverName = row.name;
        this.form.receiverPhone = phones.join(", ");
      }
    },
    handleAddressChange(ov, nv, row) {
      if (row) {
        Object.assign(this.form, {
          recProvinceCode: row.provinceCode,
          recProvince: row.provinceName,
          recCityCode: row.cityCode,
          recCity: row.cityName,
          recDistrictCode: row.districtCode,
          recDistrict: row.districtName,
          recAddress: row.address,
        });
      }
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        let now = new Date(this.$now.get());
        this.form.items = (this.form.items || []).concat(
          list.map((o) => {
            let _no = {
              goodsId: o.id,
              goodsSpec: o.specs,
              goodsName: o.name,
              code: o.code,
              erpCode: o.erpCode,
              realPrice: null,
              shipDate: new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + (o.deliveryCycle || 7)
              ).getTime(),
              count: 1,
            };
            this.loadGoodsPrice(_no);
            return _no;
          })
        );
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      let inx = this.form.items.indexOf(row);
      if (inx >= 0) this.form.items.splice(inx, 1);
    },
    pay() {
      this.$refs.pays && this.$refs.pays.show(this.form);
    },
    applyDeliveryDate() {
      if (!this.deliveryDate) return;
      this.form.items.forEach((item) => {
        item.shipDate = this.deliveryDate;
      });
    },
  },
};
</script>
